export class ApiReturn {
    status: boolean;
    message: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
    total?: number;
}

export enum RestMethod {
    get,
    post,
    put,
    update,
    delete,
    patch,
}

export enum ContentType {
    json = 'application/json',
    formData = 'multipart/form-data;',
}

export class BaseRestApiJson {
    url = '';
    restMethod: RestMethod = RestMethod.get;
    contentType?: ContentType;
    authentication?: boolean = false; //authentication: true will never workout
    pathParameters?: string;
    queryParameters?: string;
    uploadFormData?: FormData;
}

export enum StatusCode {
    OK = 'OK',
    NotFound = 'NotFound',
    InternalServerError = 'InternalServerError',
    InvalidLoginError = 'InvalidLoginError',
}