import { Injectable } from '@angular/core';
import { GlobalConfig, ToastrService } from 'ngx-toastr';

import { helpMessage, helpTitle, notFoundMessage } from '../common/helper';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  options: GlobalConfig;
  constructor(private toastr: ToastrService) {
    this.options = this.toastr.toastrConfig;
  }
  showConfigError() {
    try {
      this.showError(helpMessage);
    } catch (Ex) {
      console.error(Ex);
    }
  }

  showNotFoundInApi() {
    this.showError(notFoundMessage);
  }

  showApiError(error: string) {
    this.showError(error);
  }

  showSuccess(message: string) {
    this.toastr.success(message, helpTitle, {
      timeOut: 5000,
      progressAnimation: 'decreasing',
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    });
  }

  showError(message: string) {
    this.toastr.error(message, helpTitle, {
      timeOut: 5000,
      progressAnimation: 'decreasing',
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    });
  }

  showWarning(message: string) {
    this.toastr.warning(message, helpTitle, {
      timeOut: 5000,
      progressAnimation: 'decreasing',
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    });
  }

  showNotification(message: string) {
    this.toastr.info(message, helpTitle, {
      timeOut: 5000,
      progressAnimation: 'decreasing',
      newestOnTop: true,
      progressBar: true,
      closeButton: true,
      positionClass: 'toast-bottom-right',
    });
  }

  showInvalidLogin() {
    this.showError('Invalid login credentials try again!');
  }
}
