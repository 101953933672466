import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastService, getToken } from '..';
import { ApiReturn, BaseRestApiJson, ContentType, RestMethod } from "./helper";
import { AuthService } from '../Auth/auth.service';
import { Router } from '@angular/router';

const baseUrl: string = environment.baseUrl;
@Injectable({
  providedIn: 'root',
})
export class CommunicatorService {
  constructor(
    private http: HttpClient,
    private toasterService: ToastService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ApiRunner(apiJson: BaseRestApiJson): Observable<ApiReturn> {
    const headers = this.setHeaders(apiJson);
    const url = this.setUrl(apiJson);
    if (apiJson.restMethod === RestMethod.get) {
      return this.getApiMethod(url, headers);
    }
    if (apiJson.restMethod === RestMethod.post) {
      return this.postApiMethod(apiJson, url, headers);
    }
    if (apiJson.restMethod === RestMethod.patch) {
      return this.patchApiMethod(apiJson, url, headers);
    }
    if (apiJson.restMethod === RestMethod.put) {
      return this.putApiMethod(apiJson, url, headers);
    }
    if (apiJson.restMethod === RestMethod.delete) {
      return this.deleteApiMethod(url, headers);
    }
    throw new Error('Shouldn\'t be reachable');
  }

  private setUrl(apiJson: BaseRestApiJson) {
    let url = `${baseUrl}`;
    url = url + apiJson.url;
    if (apiJson.pathParameters) {
      url = `${url}/${apiJson.pathParameters}`;
    }
    if (apiJson.url == '' && apiJson.queryParameters) {
      url = `${baseUrl}?${apiJson.queryParameters}`;
    } else if (apiJson.url != '' && apiJson.queryParameters) {
      url = `${url}?${apiJson.queryParameters}`;
    }
    return url;
  }

  private setHeaders(apiJson: BaseRestApiJson) {
    let headers = new HttpHeaders();
    if (apiJson.contentType === ContentType.json) {
      headers = headers.set('Content-Type', apiJson.contentType);
    }
    if (apiJson.authentication) {
      headers = headers.set('Authorization', `Bearer ${getToken()}`);
    }
    headers = headers.set('Bypass-Tunnel-Reminder', "true");
    return headers;
  }

  private deleteApiMethod(url: string, headers: HttpHeaders) {
    console.log("delete", url);
    const apiReturn = this.http
      .delete<ApiReturn>(url, { headers })
      .pipe(
        catchError((err) => { return this.errorHandler(err, this.toasterService); })
      );
    return apiReturn;
  }

  private putApiMethod(apiJson: BaseRestApiJson, url: string, headers: HttpHeaders) {
    if (apiJson.uploadFormData) {
      console.log("its a form data");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      apiJson.uploadFormData.forEach((element: any) => {
        console.log({ element });
      });
    } else {
      console.log("its a json");
    }
    const apiReturn = this.http
      .put<ApiReturn>(url, (apiJson.uploadFormData) ? apiJson.uploadFormData : apiJson, { headers })
      .pipe(
        catchError((err) => { return this.errorHandler(err, this.toasterService); })
      );
    return apiReturn;
  }

  private patchApiMethod(apiJson: BaseRestApiJson, url: string, headers: HttpHeaders) {
    const uploadPayload: Partial<BaseRestApiJson> = this.prepareForPatchAndPost(apiJson);
    const apiReturn = this.http
      .patch<ApiReturn>(url, apiJson.contentType === ContentType.formData ? apiJson.uploadFormData : uploadPayload, { headers: headers })
      .pipe(
        catchError((err) => { return this.errorHandler(err, this.toasterService); })
      );
    return apiReturn;
  }

  private postApiMethod(apiJson: BaseRestApiJson, url: string, headers: HttpHeaders) {
    const uploadPayload: Partial<BaseRestApiJson> = this.prepareForPatchAndPost(apiJson);
    const apiReturn = this.http
      .post<ApiReturn>(url, (apiJson.uploadFormData) ? apiJson.uploadFormData : uploadPayload, { headers })
      .pipe(
        catchError((err) => { return this.errorHandler(err, this.toasterService); })
      );
    return apiReturn;
  }

  private prepareForPatchAndPost(apiJson: BaseRestApiJson) {
    const uploadPayload: Partial<BaseRestApiJson> = Object.assign({}, apiJson);
    if (!apiJson.uploadFormData) {
      console.log("its a json");
      delete uploadPayload.restMethod;
      delete uploadPayload.url;
      delete uploadPayload.authentication;
      delete uploadPayload.pathParameters;
      delete uploadPayload.queryParameters;
      delete uploadPayload.uploadFormData;
    }
    return uploadPayload;
  }

  private getApiMethod(url: string, headers: HttpHeaders): Observable<ApiReturn> {
    return this.http.get(url, { headers })
      .pipe(
        map((response) => { return response as ApiReturn; }),
        catchError((err) => { return this.errorHandler(err, this.toasterService); })
      );
  }

  errorHandler(error: HttpErrorResponse, toasterServiceLocal: ToastService) {
    if (error.status === 404) {
      toasterServiceLocal.showNotFoundInApi();
    } else if (error.status === 401) {
      this.userTypeNavigation();
      this.toasterService.showError("Your login has expired. Please login again");
      this.authService.logout(this.userTypeNavigation());
    } else {
      toasterServiceLocal.showApiError(error.message);
    }
    // return an observable with a user-facing error message
    return throwError(() => new Error(error.error));
  }

  responseParser(apiReturn: ApiReturn) {
    if (!apiReturn) {
      this.toasterService.showError('Something went wrong');
      return false;
    }
    if (apiReturn.status) {
      return true;
    }
    this.toasterService.showError(apiReturn.message);
    return apiReturn.status;
  }

  userTypeNavigation(): string {
    const urlParts = this.router.url.split('/');
    const typeUrl = urlParts[1];
    const userTypeMap: { [key: string]: string } = {
      'admin': 'Admin_User',
      'employee': 'Normal_user',
      'superadmin': 'Super_admin',
    };
    return userTypeMap[typeUrl];
  }
}