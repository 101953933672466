import { BaseRestApiJson, RestMethod } from "src/app/services/communicator";

export const getEmployeeAssignedLeaveListRestApiJson: BaseRestApiJson = {
    url: "",
    restMethod: RestMethod.get,
    authentication: true,
};

export const deleteEmployeeAssignedLeaveRestApiJson: BaseRestApiJson = {
    url: "",
    restMethod: RestMethod.delete,
    authentication: true,
    pathParameters: "",
};