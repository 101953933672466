import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageBusService {
  private loaderLottie = new ReplaySubject<boolean>();
  private reloadPage = new ReplaySubject<boolean>();
  private headerNameSubject = new Subject<void>();

  triggerPageLoader(status: boolean) {
    this.loaderLottie.next(status);
  }

  pageLoaderStatusChanged(): Observable<boolean> {
    return this.loaderLottie.asObservable();
  }

  // page refresh
  triggerPageRefresh(status: boolean) {
    this.reloadPage.next(status);
  }

  pageRefreshStatusChanged(): Observable<boolean> {
    return this.reloadPage.asObservable();
  }

  triggerHeaderName() {
    this.headerNameSubject.next();
  }

  getMessageForHeaderName() {
    return this.headerNameSubject.asObservable();
  }
}
