export const tokenName = "devakived";
export const themeName = "VAAS-Theme";
export const tenantName = "tenant-name";
export const tenantID = "tenant-Id";
export const adminID = "admin-Id";
export const adminName = "admin-Name";
export const userTypes = "user-type";
export const orgId = "org-name";

export function JwtTokenParser(token: string): DevakivedJwtTokenParsed | null {
    if (token && token.length > 0) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join(''),
            );
            const payloadObject = JSON.parse(jsonPayload);
            return payloadObject;
        } catch (_ex) {
            return null;
        }
    } else {
        return null;
    }
}

export interface DevakivedJwtTokenParsed {
    iss: string | undefined;
    sub: string | undefined;
    aud: string | string[] | undefined;
    nbf: number | undefined;
    jti: string | undefined;
    exp: number | undefined;
    iat: number | undefined;
    userDetails: {
        email: string | undefined;
        name: string | undefined;
    };
    userId: string | undefined;
    tenantId: string | undefined;
    orgName: string | undefined;
    userType: string | undefined;
    permissions: [] | undefined;
}

export function setToken(token: string) {
    return localStorage.setItem(tokenName, token);
}

export function setTenantName(email: string) {
    return localStorage.setItem(tenantName, email);
}

export function setTenantId(tenantId: string) {
    return localStorage.setItem(tenantID, tenantId);
}

export function setUserType(userType: string) {
    return localStorage.setItem(userTypes, userType);
}

export function setAdminId(adminId: string) {
    return localStorage.setItem(adminID, adminId);
}

export function setAdminName(adminNames: string) {
    return localStorage.setItem(adminName, adminNames);
}

export function setOrgName(orgName: string) {
    return localStorage.setItem(orgId, orgName);
}

export function AdminGetTokenAfterCheck() {
    const token = getToken();
    if (!token) return false;
    const tokenData = JwtTokenParser(token);
    if (!tokenData?.exp) return false;
    return Date.now() <= tokenData.exp * 1000 ? tokenData : false;
}

export function getToken() {
    return localStorage.getItem(tokenName);
}

export function removeToken() {
    return localStorage.clear();
}
