import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getTokenAfterCheck } from '../token';
import { AdminGetTokenAfterCheck, removeToken } from 'src/app/admin/token';
import { superAdminGetTokenAfterCheck } from 'src/app/superadmin/token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor(private router: Router) { }

  get token() {
    return getTokenAfterCheck();
  }

  logout(userType: string) {
    removeToken();
    const userTypeRoutes: { [key: string]: string } = {
      'Normal_user': '/employee/login',
      'Super_admin': '/superadmin/login',
      'Admin_User': '/admin/login'
    };
    const route = userTypeRoutes[userType] || '';
    this.router.navigate([route]);
  }

  getNameFromToken() {
    const token = AdminGetTokenAfterCheck();
    if (!token || !token.userDetails?.name) {
      this.logout("Admin_User");
      return;
    }
    return token.userDetails.name;
  }

  getSuperAdminNameFromToken() {
    const token = superAdminGetTokenAfterCheck();
    if (!token || !token.userDetails?.name) {
      this.logout("Super_admin");
      return;
    }
    return token.userDetails.name;
  }
}